import module from 'module';
import _ from "lodash";
import moment from 'moment';
import {healthCheckPhase} from 'constants/healthCheckPhase';

const templateUrl = require('./close-counters.template.html');
module.component('closeCounters', {
  templateUrl: templateUrl,
  controller: function ($location, http, authentication, notification, userCache, systemPropertyCache, branchService,
                        branchWorkingDayCache, userCounterService, currencyCache, actionCommand, confirmationTemplate, $filter) {

    // JS 'magic'
    let that = this;
    // Branch users
    that.branchUsers = [];
    // Current branch
    that.branchId = authentication.context.branchId;
    that.systemDate = null;
    that.branchName = null;
    // Organization type
    that.organizationType = null;
    // Switch indicating if counters are loaded
    that.countersLoaded = false;
    // Count of unresolved tasks registered by branch
    that.registeredTasksCount = null;
    // Count of unresolved tasks assigned to branch
    that.assignedTasksCount = null;
    // Non-cleared teller counters
    that.nonClearedCounters = [];
    // Close counter transaction date
    that.transaction = {
      endCash: null,
      endCoci: null,
      branchId: that.branchId
    };
    that.denominationDisabled = false;
    that.denominations = {};

    that.healthCheckPhase = healthCheckPhase.CLOSE_COUNTERS;
    that.organizationCurrencyIsoCode = 'PHP';

    // clear branch cache
    branchService.evict();

    const systemPropertiesSub = systemPropertyCache.toObservable()
      .combineLatest(currencyCache.toObservable(), (properties, currencies) => {
        const prop = _.find(properties, {code: 'MAIN_CURRENCY_ISO_CODE'});
        that.organizationCurrencyIsoCode = prop ? prop.value : that.organizationCurrencyIsoCode;

        that.currencies = currencies;
        that.mainCurrency = this.currencies.find(it => it.isoCode === this.organizationCurrencyIsoCode);

        that.transaction.currencyId = that.mainCurrency.id;

        return properties;
      })
      .subscribe(properties => {
        // Rear DENOMINATION_MODE property
        that.denominationLevel = _.find(properties, {code: 'DENOMINATION_LEVEL'}).value;
        // Read ORGANIZATION_TYPE property
        let organizationType = _.find(properties, {code: 'ORGANIZATION_TYPE'});
        that.organizationType = organizationType === null ? 'BANK' : organizationType.value;
        // If organization type is <> BANK -> set default values for bank properties
        if (that.organizationType !== 'BANK') {
          that.transaction.endCoci = 0;
        }
        if (that.organizationType === 'PAWNSHOP') {
          that.denominationDisabled = true;
        }
      });

    that.getCurrencyIsoCode = (currencyId) => {
      return that.currencies.find(c => c.id === currencyId).isoCode;
    };

    const branchSub = branchService.toObservable().subscribe(branches => {
      const authBranch = _.find(branches, {id: that.branchId});
      if (authBranch) {
        that.systemDate = moment(authBranch.postingDate).toDate();
        that.branchName = authBranch.name;
      }
    });

    /**
     * Read users associated with current branch
     */
    that.userCacheSubscription = userCache.toObservable().subscribe(users => {
      // Filter users matching [branchId]
      that.branchUsers.push(..._.filter(users, {branchId: that.branchId}));
      // Extend branch users with [fullName] property
      _.forEach(that.branchUsers, (user) => {
        user.fullName = `${user.firstName} ${user.middleName} ${user.lastName}`;
      })
    });

    that.getUserFullName = (userId) => {
      let user = _.find(that.branchUsers, {id: userId});
      return user ? user.fullName : 'Unknown user';
    };

    /**
     * Read counter values of users associated with current branch
     */
    http.get(`/management/users/counters?branchId=${that.branchId}`, {}, {nxLoaderText: 'Loading branch counters values'})
      .success((counters) => {
        that.nonClearedCounters = _.filter(counters, (c) => c && (c.totalCash || c.totalCheck))
          .map(c => ({
            ...c,
            isoCode: that.getCurrencyIsoCode(c.currencyId)
          }));
        that.countersLoaded = true;
      })
      .error(() => notification.show("Failed to load branch counter values"));

    /**
     * Close counter action should be available if branch
     * counter values are loaded and there is no non-cleared
     * TELLER counters.
     */
    that.validateCounterState = () => {
      return that.countersLoaded && (!that.nonClearedCounters || that.nonClearedCounters.length === 0);
    };

    that.validateLoans = () => {
      if (that.checks && that.checks.Loan && that.checks.Loan.length > 0) {
        return !that.checks.Loan.some(c => c.status === 'FAILED');
      } else {
        return true;
      }
    };

    that.validatePawns = () => {
      if (that.checks && that.checks.Pawn && that.checks.Pawn.length > 0) {
        return !that.checks.Pawn.some(c => c.status === 'FAILED');
      } else {
        return true;
      }
    };

    /**
     * Read pending tasks registered by branch
     */
    http.get(`/tasks/counter?branchId=${that.branchId}&displayMode=OWNED&status=PENDING`, {}, {nxLoaderText: 'Loading registered tasks'})
      .success((count) => that.registeredTasksCount = count)
      .error(() => notification.show("Failed to load registered tasks"));

    /**
     * Read pending tasks assigned to branch
     */
    http.get(`/tasks/counter?branchId=${that.branchId}&displayMode=ASSIGNED&status=PENDING`, {}, {nxLoaderText: 'Loading assigned tasks'})
      .success((count) => that.assignedTasksCount = count)
      .error(() => notification.show("Failed to load assigned tasks"));

    http.get(`/system/health-details?includeStatement=true&phases=${that.healthCheckPhase}&branchId=${that.branchId}`)
      .success(data => {
        that.checks = data;
      });

    /**
     * Close counter action should be available if number of unresolved tasks
     * both: registered-by and assigned-to is 0
     */
    that.validateTasks = () => {
      return that.registeredTasksCount === 0 && that.assignedTasksCount === 0;
    };

    const fetchAutoDenomination = () => {
      // If currency is not given or denomination is disabled -> return null as data provider result
      if (!that.denominationLevel || that.denominationLevel === 'NONE') return null;
      // Otherwise fetch current branch denomination
      // TODO = fetch currency value by ISO code
      const currencyId = that.mainCurrency.id;
      http.get(`/currencies/denomination/branches/${that.branchId}?currencyId=${currencyId}`, {}, {nxLoaderText: 'Loading auto denomination'})
        .success((branchDenomination) => {
          // Transform branch denomination into denomination bundle
          that.autoDenomination = {
            incoming: {
              denomination: {
                currencyId: 2,
                units: branchDenomination.summary
              }
            }
          };
        })
        .error(() => {
          notification.show("Failed to load auto denomination");
          that.denominationDisabled = false;
        });
    };

    /**
     * Read vault ending cash and ending COCI.
     * Fetched values must be passed as transaction data.
     */
    http.get(`/management/branches/${that.branchId}/counter`, {}, {nxLoaderText: 'Loading branch counter'})
      .success((counter) => {
        that.transaction.endCash = counter.totalCash;
        that.transaction.endCoci = counter.totalCheck;
        fetchAutoDenomination();
      })
      .error(() => notification.show("Failed to load branch counter"));

    /**
     * Close counter action should be enabled
     * if both: ending cash and ending COCI are given
     */
    that.closeActionAvailable = () => {
      return that.transaction.endCash !== null && that.transaction.endCoci !== null;
    };

    that.cancel = () => {
      $location.path("/dashboard/miscellaneous-transactions");
    };

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    //todo: extract service
    that.flattenDenominations = () => {
      const flatDenominations = [];

      for (let key in that.denominations) {
        const denomination = that.denominations[key];
        if (denomination) {
          const denominationBundle = denomination.denominationBundle;
          flatDenominations.push(denominationBundle);
        }
      }
      return flatDenominations;
    };

    that.allValid = () => that.validateCounterState()
      && that.validateTasks()
      && that.validateLoans()
      && that.validatePawns();

    that.save = () => {
      confirmationTemplate({
        question: 'Do you want to close counters?',
        details: [
          {label: 'Branch', description: that.branchName},
          {label: 'Date', description: $filter('prettyDate')(that.systemDate)}
        ],
        warning: 'This operation cannot be reverted safely.<br>Please make sure that the counter can be closed.',
        yesCallback: () => {
          that.transaction.foreginDenominationBundles = that.flattenDenominations();
          actionCommand.execute('CLOSE_COUNTER', that.transaction, () => {
            branchWorkingDayCache.withParam(authentication.context.branchId).refetch();
            userCounterService.refresh();
            $location.path("/dashboard/miscellaneous-transactions");
          });
        }
      });

    };

    that.$onDestroy = () => {
      systemPropertiesSub.unsubscribe();
      branchSub.unsubscribe();
      that.userCacheSubscription.unsubscribe();
    }
  }
});
