import $ from 'jquery';
import _ from 'lodash';
import module from 'module';
import {NgTableParams} from 'ng-table/ng-table';
import {MODE} from '../stock-items-inventory-view.component';

import templateUrl from './stock-items-list.template.html';
import './stock-items-list.style.less';

module.component('stockItemsList', {
  templateUrl: templateUrl,
  bindings: {
    customerId: '<',
    showAddItem: '<',
    checkedMode: '=',
    checkedItems: '='
  },
  controller: function ($location, http, confirmation, branchService, pawnCache, pawnItemTypeCache,
                        pawnItemDefectTypeCache, stockedItemMetalRateCache, pawnItemAttributeTypeCache, pawnMetalTypesCache,
                        command, pawnService, reportService, $route, systemPropertyCache, commandAccessChecker) {
    const that = this;

    const reportCode = 'StockInventoryItemsReport';

    that.MODE = MODE;

    that.params = {};
    that.metals = [];

    that.pawnItemTypes = [];
    that.pawnItemDefectTypes = [];
    that.metalRates = [];
    that.pawnItemAttributeTypes = [];
    that.branches = [];
    that.qualifiedItemsToHO = [];
    that.checkedForDisplay = [];
    that.checkedForSell = [];
    that.checkedForRepricing = [];

    that.chkSendToDisplayAll = false;
    that.chkSendToHoDisplayAll = false;
    that.chkAllForSale = false;
    that.enableReturnToHO = false;
    that.chkRepriceAll = false;

    that.pawnItems = [];

    that.filterConfig = {
      reportCode: reportCode,
      buttons: {
        filter: {
          isVisible: true,
          isDisabled: false,
          action: () => that.filter(),
          text: 'Filter'
        }, download: {
          isVisible: true,
          isDisabled: false,
          action: () => that.downloadXls(),
          text: 'Download xls'
        }, print: {
          isVisible: false,
          isDisabled: false,
          action: null,
          text: 'Print'
        }
      }
    };

    systemPropertyCache.toObservable().first().subscribe(properties => {
      that.headOfficeId = _.find(properties, {code: 'HEAD_OFFICE_BRANCH_ID'}).value;
    });

    branchService.toObservable().first().subscribe(branches => {
      that.branches = branches;
    });

    // Read pawn meta types
    pawnMetalTypesCache.toObservable().subscribe(types => that.metals = types);

    // Read pawn item types
    pawnItemTypeCache.toObservable().subscribe(itemTypes => {
      that.pawnItemTypes = itemTypes;
    });

    // Read pawn item defect types
    pawnItemDefectTypeCache.toObservable().subscribe(defectTypes => {
      that.pawnItemDefectTypes = defectTypes;
    });

    // Read pawn metal rates
    stockedItemMetalRateCache.toObservable().subscribe(rates => {
      that.metalRates = rates;
    });

    // Read pawn item attributes types
    pawnItemAttributeTypeCache.toObservable().subscribe(attributeTypes => {
      that.pawnItemAttributeTypes = attributeTypes;
    });

    commandAccessChecker.canExecuteCommandObservable()
      .subscribe(canExecuteCommand => {
        this.isReprice = canExecuteCommand('RepriceStockedItems');
      });


    that.getTypeLabel = (typeId) => {
      let type = _.find(that.pawnItemTypes, {id: typeId});
      return type && type.name ? type.name : '-';
    };

    that.filter = () => {
      // on filter change
      that.pawnItems = [] // hide previously paginated results
      that.tableConfig.page(1); // set up first page as default
      that.tableConfig.reload(); // get new results
    };

    that.downloadXls = () => reportService.downloadXls({
      reportCode,
      params: {
        ...that.params,
        customerId: that.customerId // hidden parameter
      },
      reportName: 'Pawned_Stock_Items_Report.xlsx'
    });

    // Call service for default filter config
    that.tableConfig = new NgTableParams({
      count: 50,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: params => {
        const itemCategoryExtended = JSON.parse(that.params.itemCategoryExtendedCode);
        const queryParams = {
          pageNo: params.page() - 1,
          pageSize: params.count(),
          customerId: that.customerId,
          categoryId: (itemCategoryExtended || {}).categoryId || null,
          isPlain: (itemCategoryExtended || {}).isPlain,
          typeIds: that.params['itemTypeExtendedCode[]'],
          tagIds: that.params['pawnTagId[]'],
          status: that.params.pawnStatus || null,
          branchIds: that.params.branchId || null,
          auctionId: that.params.pawnAuctionId || null,
          itemNumber: that.params.pawnItemNumber || null,
          ticketNumber: that.params.pawnTicketNumber || null,
          officialReceiptNumber: that.params.officialReceiptNumber,
          dateFrom: that.params.date_range.from,
          dateTo: that.params.date_range.to
        };

        that.enableReturnToHO = that.params.branchId === null || Number(that.headOfficeId) !== that.params.branchId;

        return http.get(`/products/pawns/stock-items?${$.param(queryParams, true)}`, {nxLoaderText: 'Loading stock items'})
          .success(async (items) => {
            that.chkSendToDisplayAll = false;
            that.chkSendToHoDisplayAll = false;
            that.chkAllForSale = false;
            that.chkRepriceAll = false;
            that.tableConfig.total(items.totalCount);

            const selectedToSendPawnItemIdSet = new Set(that.checkedForDisplay.map(i => i.id));
            const selectedToHOPawnItemIdSet = new Set(that.qualifiedItemsToHO.map(i => i.id));
            const selectedToSellPawnItemIdSet = new Set(that.checkedForSell.map(i => i.id));
            const selectedForRepricingPawnItemIdSet = new Set(that.checkedForRepricing.map(i => i.id));

            const preparedItems = await Promise.all(
              items.result.map(
                async pawnItem => {
                  return {
                    ...pawnItem,
                    toSend: selectedToSendPawnItemIdSet.has(pawnItem.id),
                    toHO: selectedToHOPawnItemIdSet.has(pawnItem.id),
                    toSell: selectedToSellPawnItemIdSet.has(pawnItem.id),
                    forRepricing: selectedForRepricingPawnItemIdSet.has(pawnItem.id),
                    branchName: _.find(that.branches, {id: pawnItem.branchId}).name,
                    categoryLabel: that.getTypeLabel(pawnItem.categoryId),
                    typeLabel: that.getTypeLabel(pawnItem.typeId),
                    subtypeLabel: that.getTypeLabel(pawnItem.subtypeId),
                    details: that.readItemDetails(pawnItem),
                  };
                }
              )
            );

            that.tableConfig.data = that.pawnItems = preparedItems;
          });
      }
    });

    that.tableConfig.page(1);

    that.itemClicked = (item, $event) => {
      $event.stopPropagation();
      // let either input or item selection action at once
      if ($event.target.nodeName === 'INPUT') {
        return;
      }
      that.selectedItemId = item.id;
    };

    that.hideInlinePanel = () => {
      that.selectedItemId = null;
    };

    that.readItemDetails = (item) => {
      return pawnService.buildDescription(item, that.pawnItemDefectTypes, that.pawnItemTypes, that.metals, that.pawnItemAttributeTypes, that.metalRates);
    };

    that.checkItem = (mode, item) => {
      let isChecked = false;
      let items = [];

      switch (mode.name) {
        case that.MODE.SEND_FOR_DISPLAY.name: {
          isChecked = item.toSend
          items = that.checkedForDisplay;
          break;
        }
        case that.MODE.SEND_TO_HO.name: {
          isChecked = item.toHO;
          items = that.qualifiedItemsToHO;
          break;
        }
        case that.MODE.SELL.name: {
          isChecked = item.toSell
          items = that.checkedForSell;
          break;
        }
        case that.MODE.REPRICE_STOCKED_ITEMS.name: {
          isChecked = item.forRepricing,
          items = that.checkedForRepricing;
          break;
        }
      }
      const find = items.map(i => i.id).indexOf(item.id);
      if (isChecked && find === -1) {
        items.push(item);
      } else {
        if (find > -1) {
          items.splice(find, 1);
        }
      }
      return items;
    };

    that.goToSellPage = (item) => {
      $location.path(`/dashboard/stocked-items/item/${item.id}/sell`);
    };

    that.goToReservationPage = (item) => {
      if (item.reservation) {
        $location.path(`/customer/${item.reservation.customerId}/stocked-items/item/${item.id}/reservation`);
      } else {
        $location.path(`/dashboard/stocked-items/item/${item.id}/reserve`);
      }
    };

    that.goToEditPage = (item) => {
      $location.path(`/dashboard/stocked-items/item/${item.id}/edit`);
    };

    that.selectAllForSale = () => {
      _.forEach(that.tableConfig.data, line => {
        const find = that.checkedForSell.map(i => i.id).indexOf(line.id);
        if (that.chkAllForSale) {
          if (line.status === 'READY_FOR_SALE' && find === -1) {
            that.checkedForSell.push(line);
            line.toSell = true;
          }
        } else {
          line.toSell = false;
          if (find > -1) {
            that.checkedForSell.splice(find, 1);
          }
        }
      });
    };

    that.sendToDisplayAll = () => {
      _.forEach(that.tableConfig.data, line => {
        const find = that.checkedForDisplay.map(i => i.id).indexOf(line.id);
        if (that.chkSendToDisplayAll) {
          if (that.isSendToDisplayCheckboxEnabled(line) && find === -1) {
            that.checkedForDisplay.push(line);
            line.toSend = true;
          }
        } else {
          line.toSend = false;
          if (find > -1) {
            that.checkedForDisplay.splice(find, 1);
          }
        }
      });
    };

    that.sendToHoDisplayAll = () => {
      _.forEach(that.tableConfig.data, line => {
        const find = that.qualifiedItemsToHO.map(i => i.id).indexOf(line.id);
        if (that.chkSendToHoDisplayAll) {
          if (line.status === 'READY_FOR_SALE' && find === -1) {
            that.qualifiedItemsToHO.push(line);
            line.toHO = true;
          }
        } else {
          line.toHO = false;
          if (find > -1) {
            that.qualifiedItemsToHO.splice(find, 1);
          }
        }
      });
    };

    that.showSendReport = (mode, items) => {
      that.checkedMode = mode;
      that.checkedItems = items;
    };

    that.addItem = () => {
      $location.path('/dashboard/stocked-items/item/create');
    };

    that.batchUpload = () => {
      $location.path('/dashboard/stocked-items/batch-upload');
    }

    that.isSendToDisplayCheckboxEnabled = (line) => {
      return ['NEW', 'UNSOLD'].includes(line.status)
    }

    that.isSellCheckboxEnabled = (line) => {
      return ['READY_FOR_SALE'].includes(line.status)
    }


    /**
     * Repricing methods below
     */
    that.isEligibleForRepricing = (item) => {
      return ['NEW', 'UNSOLD', 'READY_FOR_SALE'].includes(item.status) && item.categoryLabel == 'Jewelry';
    }

    that.showRepriceReport = (mode, items) => {
      that.checkedMode = mode;
      that.checkedItems = items;
    };

    that.repriceAll = () => {
      _.forEach(that.tableConfig.data, line => {
        const index = that.checkedForRepricing.map(i => i.id).indexOf(line.id);
        if (that.chkRepriceAll) {
          if (that.isEligibleForRepricing(line) && index === -1) {
            that.checkedForRepricing.push(line);
            line.forRepricing = true;
          }
        } else {
          line.forRepricing = false;
          if (index > -1) {
            that.checkedForRepricing.splice(index, 1);
          }
        }
      });
    }
  }
});
