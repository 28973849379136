import module from 'module';
import _ from 'lodash';

import templateUrl from './cash-transfer-to-cashier.template.html';

class CashTransferToCashierView {

  constructor(authentication, actionCommand, userCache, ledgerActionTemplateCache, commandRoleMapCache,
              miscTransactionsService, commandAccessChecker, branchCurrencyCache) {
    this.authentication = authentication;
    this.actionCommand = actionCommand;
    this.userCache = userCache;
    this.ledgerActionTemplateCache = ledgerActionTemplateCache;
    this.commandRoleMapCache = commandRoleMapCache;
    this.miscTransactionsService = miscTransactionsService;
    this.commandAccessChecker = commandAccessChecker;
    this.branchCurrencyCache = branchCurrencyCache;
  }

  async $onInit() {
    this.currencies = await this.branchCurrencyCache.withParam(Number(this.authentication.context.branchId)).toPromise();
    const mainCurrencyIdx = _.findIndex(this.currencies, it => it.isoCode === 'PHP');
    if (mainCurrencyIdx > -1) {
      this.currencies.splice(mainCurrencyIdx, 1);
    }

    this.transaction = {};
    this.approvalUsers = [];

    const [users, templates, canApproveCommand] = await Promise.all([
      this.userCache.toPromise(), this.ledgerActionTemplateCache.toPromise(), this.commandAccessChecker.canApproveCommandPromise()]);
    const template = _.find(templates, {type: this.actionType});
    template.commandName = this.actionCommand.actionTypeToCommand(template.type);
    const branchId = this.authentication.context.branchId;
    this.approvalUsers = this.miscTransactionsService.filterApprovalUsers(branchId, template, users, canApproveCommand)
      .filter(approvalUser => approvalUser.roles.some(au => au.cashierCounter));
  }

  getTitle() {
    return _.capitalize((this.forex ? 'forex ' : '') + 'cash transfer to cashier');
  }

  refreshAmountCurrency() {
    this.currency = this.currencies.find(c => c.id === this.transaction.currencyId);
  }

  cancelChanges() {
    this.actionCommand.cancelChanges();
  }

  save() {
    this.actionCommand.executeAndShowValidation(this.actionType, this.transaction);
  }
}

module.component('cashTransferToCashier', {
  templateUrl: templateUrl,
  bindings: {
    actionType: '@',
    forex: '<'
  },
  controller: CashTransferToCashierView
});
