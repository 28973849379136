import module from 'module';
import _ from 'lodash';
import $ from 'jquery';

const templateUrl = require('./new-customer.template.html');

class NewCustomerView {
  constructor($scope, http, $timeout, $location, dict, notification, propertyConfigService,
              authentication, systemPropertyCache, modalPrintPreviewService, command, popup, watchlistService) {
    this.$scope = $scope;
    this.http = http;
    this.$timeout = $timeout;
    this.$location = $location;
    this.dict = dict;
    this.notification = notification;
    this.propertyConfigService = propertyConfigService;
    this.authentication = authentication;
    this.systemPropertyCache = systemPropertyCache;
    this.modalPrintPreviewService = modalPrintPreviewService;
    this.command = command;
    this.popup = popup;
    this.watchlistService = watchlistService;
  }

  $onInit() {
    this.cfg = this.propertyConfigService;

    this.organizationType = null;
    this.singlePage = null;
    this.corporateCustomerEnabled = null;
    this.defaultUrl = null;
    this.watchlistVerification = this.watchlistService.emptyVerification();

    this.customerTypes = [
      {id: 'INDIVIDUAL', description: 'Individual customer'},
      {id: 'CORPORATE', description: 'Corporate customer'}
    ];

    this.forms = [this.individualDataForm, this.corporateDataForm, this.documentsForm, this.addressesForm, this.incomeForm, this.depositoryForm, this.relatedPeopleForm];

    // The model must have the same structure as the original model
    this.profile = {
      customerType: 'INDIVIDUAL',

      individualData: {
        pep: false,
        sanctionedEntity: false,
        vip: false,
        employee: false,
        dosri: false
      },

      corporateData: {
        sanctionedEntity: false
      },
      externalAccounts: [],

      addresses: [{}],
      idDocuments: [{}],
      relatives: [],
      extractedPhones: {}
    };

    const clonedModel = $.extend(true, {}, this.profile);

    const systemPropertiesSub = this.systemPropertyCache.toObservable().subscribe(properties => {
      const organizationType = _.find(properties, {code: 'ORGANIZATION_TYPE'});
      this.organizationType = organizationType === null ? 'BANK' : organizationType.value;
      const singlePageProp = _.find(properties, {code: 'CIF_SINGLE_PAGE'});
      this.singlePage = singlePageProp && singlePageProp.value === 'TRUE';
      const corpoProp = _.find(properties, {code: 'CIF_CORPORATE_SUPPORT'});
      this.corporateCustomerEnabled = corpoProp && corpoProp.value === 'TRUE';
      const defaultUrl = _.find(properties, {code: 'DEFAULT_URL_AFTER_CST_LOOKUP'});
      this.defaultUrl = defaultUrl && defaultUrl.value ? defaultUrl.value : '/customer/{}/accounts';
    });

    this.dict.onLoadingComplete(() => {
      const defaultRiskLevel = this.dict.getId('CUSTOMER_RISK_LEVEL', 'LOW');
      this.profile.individualData.riskLevelId = clonedModel.individualData.riskLevelId = defaultRiskLevel;
      this.profile.corporateData.riskLevelId = clonedModel.corporateData.riskLevelId = defaultRiskLevel;

      const ph = this.dict.getId('COUNTRY', 'PH');
      this.profile.individualData.citizenshipId = clonedModel.individualData.citizenshipId = ph;
      this.profile.individualData.residenceId = clonedModel.individualData.residenceId = ph;

      // FIXME = default values for country codes should be fetched from [cfg]
      this.profile.addresses[0].countryId = ph;
      this.profile.idDocuments[0].countryId = ph;
    });

    this.$timeout(() => {
      // show validation problems on form:
      this.forms.forEach(form => {
        if (form) form.$setSubmitted();
      })
    });
  }

  getProfile() {
    return this.profile;
  }

  reset(forceReset) {
    if (forceReset || this.confirm("Do you really want to reset all fields?")) {
      this.profile = $.extend(true, {}, this.clonedModel);

      this.forms.forEach(form => {
        if (form) form.$setPristine();
      })
    }
  };

  prepareCustomerForSaving(profile) {
    if (profile.customerType == 'INDIVIDUAL') {
      profile.corporateData = null;
      // signature
      let signatureFileId = _.head(profile.individualData.signatureFile);
      if (signatureFileId && signatureFileId.uploaded) {
        profile.individualData.signatureFileId = signatureFileId.id;
      }

      // profile picture
      let pictureFileId = _.head(profile.signatureFile);
      if (pictureFileId && pictureFileId.uploaded) {
        profile.pictureFileId = pictureFileId.id;
      }
    } else {
      profile.individualData = null;
      profile.incomeSources = null; // Since corporate customers don't support income sources for now, set to null so it doesn't trigger backend validations.
      if (that.organizationType == 'PAWNSHOP') { //&& CORPORATE from surrounding if
        profile.idDocuments = [];
      }
    }

    profile.branchId = this.authentication.context.branchId;
    profile.userId = this.authentication.context.id;
    profile.status = 'PROSPECT';
    profile.verified = true;

    // sets "primary" attributes
    for (let i = 0; i < profile.idDocuments.length; i++) {
      profile.idDocuments[i].primary = (i === 0);
      profile.idDocuments[i].orderNo = i;
      profile.idDocuments[i].photoFileIds = profile.idDocuments[i].photos ? profile.idDocuments[i].photos.map(photo => photo.id) : null;
      delete profile.idDocuments[i].photos;
    }

    for (let i = 0; i < profile.addresses.length; i++) {
      profile.addresses[i].primary = (i == 0);
      profile.addresses[i].orderNo = i;
    }

    if (profile.incomeSources && profile.incomeSources.length) {
      for (let i = 0; i < profile.incomeSources.length; i++) {
        profile.incomeSources[i].primary = (i == 0);
        profile.incomeSources[i].orderNo = i;
        if (profile.incomeSources[i].companyAddress) profile.incomeSources[i].companyAddress.primary = true;
      }
    }

    if (profile.individualData) {
      delete profile.individualData.signatureFile;
      delete profile.signatureFile;
    }


    // phones
    profile.phones = [];
    let phonePush = (phone, type) => {
      if (phone && phone.number && type) {
        profile.phones.push({
          type: type,
          number: phone.number,
          primary: false
        });
      }
    };

    phonePush(profile.extractedPhones.home, 'HOME');
    phonePush(profile.extractedPhones.work, 'WORK');
    phonePush(profile.extractedPhones.mobile, 'MOBILE');
  };

  /**
   * After successful customer creation user should be redirected to other page.
   *
   * For PAWNSHOP -> redirect to new pawn
   * For BANK -> redirect to actions
   */
  performRedirect(customerId) {
    this.http.get(`/customers/${customerId}`)
      .success(() => {
        let redirectionUrl = this.defaultUrl.replace('{}', customerId);
        this.$location.path(redirectionUrl);
        // if customer is prospect => 404 will be returned
      }).error(redirectBack)

  };

  redirectBack() {
    this.$location.path('/dashboard/lookup-customer');
  }

  saveCustomer() {
    const profile = _.cloneDeep(this.getProfile());
    this.prepareCustomerForSaving(profile);

    this.command.execute(
      this.watchlistService.getCreateCustomerCommand(this.watchlistVerification),
      this.watchlistService.createCommandInput(this.watchlistVerification, profile)
    ).success((res) => {
      let customerId = res.output.id;
      const onComplete = () => {
        this.reset(true);
        this.performRedirect(customerId);
        this.notification.show('Success', 'Customer profile created successfully.');
      };
      if (profile.customerType === 'INDIVIDUAL') {
        this.modalPrintPreviewService.show('SIGNATURE_CARD', {customerId: customerId}, () => {
          onComplete();
        });
      } else {
        onComplete();
      }
    });
  };

  resetWatchlistVerification() {
    this.watchlistVerification = {
      completed: false
    }
  }

  async verifyWatchlist(input){
    this.watchlistVerification = await this.watchlistService.verify(input);
  }

  $onDestroy() {
    this.systemPropertiesSub.unsubscribe();
  }
}

module.component('newCustomer', {
  templateUrl: templateUrl,
  controller: NewCustomerView
});
